<template>
  <div class="main-view">
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button :label="1">待审核{{table.audit_total}}</el-radio-button>
        <el-radio-button :label="2">已审核{{table.sign_total}}</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_name">
          <el-input clearable v-model.trim="table.params.company_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input clearable v-model.trim="table.params.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="table.params.status == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:company="{row}">
        <span v-if="!!row.company">{{row.company.name}}</span>
      </template>
      <template v-slot:status="{row}">
        <div>{{toStr({1:'', 2:'通过', 3:'不通过'},row.status)}}</div>
        <div>{{row.check_at}}</div>
      </template>
      <template v-slot:type="{row}">
        <span v-for="item in row.update_fields" :key="item">{{dataText[item] ? `【${dataText[item]}】` : ''}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">{{table.params.status == 1 ? '审核' : '查看'}}</el-button>
      </template>
    </VTable>

    <detail ref="detail" @refresh="getTable"></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue';
import {getStorage} from '@/storage'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
  },
  data() {
    return {
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "company", label: "用工单位", hidden: false},
        { name: "type", label: "申请变更", showTooltip:true, hidden: false},
        { name: "submit_at", label: "申请时间",  hidden: false},
        { name: "action", label: "操作", width:'100', hidden: false},
      ],
      field2: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "company", label: "用工单位", hidden: false},
        { name: "type", label: "申请变更", showTooltip:true, hidden: false},
        { name: "submit_at", label: "申请时间",  hidden: false},
        { name: "status", label: "审核结果", width:"160",  hidden: false},
        { name: "action", label: "操作", width:'100', hidden: false},
      ],
      
      table: {
        loading: false,
        params: {
          name: '',
          phone: '',
          company_name: '',
          status: 1,	// 审核状态：1-待审核；2-已审核；
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },

      dataText:{
        // 基本信息
        residence: '现居地址',
        emergency_contact: '紧急联系人',
        emergency_contact_relation:'所属关系',
        phone: '联系电话',
        emergency_contact_relation: '所属关系',
        emergency_contact_phone: '联系电话',
        email: '电子邮箱',
        political_outlook_text: '政治面貌',
        marital_status_text: '婚姻状况',
        licence_img: '1寸证件照',
        diploma: '毕业照',

        // 其他信息
        bank_card_img: '银行卡正面',
        bank_card_no: '银行卡号',
        bank_name: '开户行',
        bank_card_name: '持卡人姓名',
        has_relative: '有无亲属在公司',
        // 有无亲属在公司
        relative_relation: '与本人关系',
        relative_name: '姓名',
        relative_title: '职务',
        // 保险
        has_endowment: '有无养老保险',
        has_medical: '有无医疗保险',
        has_unemployment: '有无失业保险',
        has_birth: '有无生育保险',
        has_injury: '有无工伤保险',
        has_fund: '有无住房公积金',
        medical_report_img: '体检报告图片',
        educations: '学历信息',
        jobs: '工作信息',
        members: '亲属信息',

      }

    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/userSubmit/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 审核
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
  }
}
</script>
<style >
.el-tooltip__popper {
  max-width: 50%;
}
</style>