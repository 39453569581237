<template>
  <el-dialog
    append-to-body
    width="850px"
    :title="toStr({1:'审核',2:'审核通过',3:'审核拒绝'},detailInfo.status)"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
  >
    <div class="tableWrap">
      <el-descriptions class="margin-top" title="" :column="3" border >
        <el-descriptions-item :labelStyle="{width:'100px'}" :contentStyle="{width:'160px'}" label="员工姓名">{{detailInfo.name}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'100px'}" :contentStyle="{width:'160px'}" label="联系电话">{{detailInfo.phone}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'100px'}" :contentStyle="{width:'260px'}" label="用工单位">{{detailInfo.company_name}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'100px'}" :contentStyle="{width:'260px'}" label="提交时间">{{detailInfo.created_at}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'100px'}" :contentStyle="{width:'260px'}" label="审核时间" v-if="!!detailInfo.check_at">{{detailInfo.check_at}}</el-descriptions-item>
        <el-descriptions-item :labelStyle="{width:'100px'}" :contentStyle="{width:'260px'}" label="原因" v-if="!!detailInfo.check_reason">{{detailInfo.check_reason}}</el-descriptions-item>
      </el-descriptions>
      <!-- <el-divider content-position="left">更新内容</el-divider> -->
      <div style="margin:20px 0;">更新内容：</div>
      <div class="uploadWrap">
        <div>
          <!-- <div v-for="(item,index) in update_fields" :key="index">
            <span>{{item}}：</span>
            <span>{{origin_user_data.item}}</span>
            <span style="margin: 0 10px;">改</span>
            <span>{{new_user_data.item}}</span>
          </div> -->

          <!--****** 基本信息 *******-->
          <div>
            <!-- 联系电话 -->
            <div class="line" v-if="update_fields.includes('phone')">
              <span class="label_txt">{{dataText.phone}}：</span>
              <span>{{origin_user_data.phone}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.phone}}</span>
            </div>
            <!-- 现居住地 -->
            <div class="line" v-if="update_fields.includes('residence')">
              <span class="label_txt">{{dataText.residence}}：</span>
              <span>{{origin_user_data.residence}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.residence}}</span>
            </div>
            <!-- 紧急联系人 -->
            <div class="line" v-if="update_fields.includes('emergency_contact')">
              <span class="label_txt">{{dataText.emergency_contact}}：</span>
              <span>{{origin_user_data.emergency_contact}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.emergency_contact}}</span>
            </div>
            <!-- 所属关系 -->
            <div class="line" v-if="update_fields.includes('emergency_contact_relation')">
              <span class="label_txt">{{dataText.emergency_contact_relation}}：</span>
              <span>{{origin_user_data.emergency_contact_relation}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.emergency_contact_relation}}</span>
            </div>
            <!-- 联系电话 -->
            <div class="line" v-if="update_fields.includes('emergency_contact_phone')">
              <span class="label_txt">{{dataText.emergency_contact_phone}}：</span>
              <span>{{origin_user_data.emergency_contact_phone}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.emergency_contact_phone}}</span>
            </div>
            <!-- 电子邮箱 -->
            <div class="line" v-if="update_fields.includes('email')">
              <span class="label_txt">{{dataText.email}}：</span>
              <span>{{origin_user_data.email}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.email}}</span>
            </div>
            <!-- 政治面貌  -->
            <div class="line" v-if="update_fields.includes('political_outlook_text')">
              <span class="label_txt">{{dataText.political_outlook_text}}：</span>
              <span>{{origin_user_data.political_outlook_text}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.political_outlook_text}}</span>
            </div>
            <!-- 婚姻状况  -->
            <div class="line" v-if="update_fields.includes('marital_status_text')">
              <span class="label_txt">{{dataText.marital_status_text}}：</span>
              <span>{{origin_user_data.marital_status_text}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.marital_status_text}}</span>
            </div>
            <!-- 1寸证件照  -->
            <div class="line" v-if="update_fields.includes('licence_img')">
              <span class="label_txt">{{dataText.licence_img}}：</span>
              <el-image
                style="width: 40px; height: 40px"
                :src="origin_user_data.licence_img"
                fit="cover" :preview-src-list="[origin_user_data.licence_img]">
                  <div slot="error" class="image-slot" style="height:20px;line-height:40px;">
                   无
                  </div>
                </el-image>
              <span style="margin: 0 10px;">改</span>
              <el-image
                style="width: 40px; height: 40px"
                :src="new_user_data.licence_img"
                fit="cover" :preview-src-list="[new_user_data.licence_img]">
                  <div slot="error" class="image-slot" style="height:20px;line-height:40px;">
                   无
                  </div>
                </el-image>
            </div>
          </div>


          <!--****** 其他信息 ******-->
          <div>
            <!-- 银行卡正面 -->
            <div class="line" v-if="update_fields.includes('bank_card_img')">
              <span class="label_txt">{{dataText.bank_card_img}}：</span>            
              <el-image
                style="width: 40px; height: 40px"
                :src="origin_user_data.bank_card_img"
                fit="cover" :preview-src-list="[origin_user_data.bank_card_img]">
                  <div slot="error" class="image-slot" style="height:20px;line-height:40px;">
                   无
                  </div>
                </el-image>
              <span style="margin: 0 10px;">改</span>            
              <el-image
                style="width: 40px; height: 40px"
                :src="new_user_data.bank_card_img"
                fit="cover" :preview-src-list="[origin_user_data.bank_card_img]">
                  <div slot="error" class="image-slot" style="height:20px;line-height:40px;">
                    无
                  </div>
                </el-image>
            </div>
            <!-- 银行卡号 -->
            <div class="line" v-if="update_fields.includes('bank_card_no')">
              <span class="label_txt">{{dataText.bank_card_no}}：</span>
              <span>{{origin_user_data.bank_card_no}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.bank_card_no}}</span>
            </div>
            <!-- 开户银行 -->
            <div class="line" v-if="update_fields.includes('bank_name')">
              <span class="label_txt">{{dataText.bank_name}}：</span>
              <span>{{origin_user_data.bank_name}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.bank_name}}</span>
            </div>
            <!-- 持卡人姓名 -->
            <div class="line" v-if="update_fields.includes('bank_card_name')">
              <span class="label_txt">{{dataText.bank_card_name}}：</span>
              <span>{{origin_user_data.bank_card_name}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.bank_card_name}}</span>
            </div>
            <!-- 有无亲属在公司 -->
            <div class="line" v-if="update_fields.includes('has_relative')">
              <span class="label_txt">{{dataText.has_relative}}：</span>
              <span>{{origin_user_data.has_relative == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_relative == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 有亲属时暂时 -->
            <template v-if="new_user_data.has_relative == 1">
              <!-- 与本人关系 -->
              <div class="line" v-if="update_fields.includes('relative_relation')">
                <span class="label_txt">{{dataText.relative_relation}}：</span>
                <span>{{origin_user_data.relative_relation}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.relative_relation}}</span>
              </div>
              <!-- 姓名 -->
              <div class="line" v-if="update_fields.includes('relative_name')">
                <span class="label_txt">{{dataText.relative_name}}：</span>
                <span>{{origin_user_data.relative_name}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.relative_name}}</span>
              </div>
              <!-- 职务 -->
              <div class="line" v-if="update_fields.includes('relative_title')">
                <span class="label_txt">{{dataText.relative_title}}：</span>
                <span>{{origin_user_data.relative_title}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.relative_title}}</span>
              </div>
            </template>
            <!-- 有无养老保险 -->
            <div class="line" v-if="update_fields.includes('has_endowment')">
              <span class="label_txt">{{dataText.has_endowment}}：</span>
              <span>{{origin_user_data.has_endowment == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_endowment == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 有无医疗保险 -->
            <div class="line" v-if="update_fields.includes('has_medical')">
              <span class="label_txt">{{dataText.has_medical}}：</span>
              <span>{{origin_user_data.has_medical == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_medical == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 有无失业保险 -->
            <div class="line" v-if="update_fields.includes('has_unemployment')">
              <span class="label_txt">{{dataText.has_unemployment}}：</span>
              <span>{{origin_user_data.has_unemployment == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_unemployment == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 有无生育保险 -->
            <div class="line" v-if="update_fields.includes('has_birth')">
              <span class="label_txt">{{dataText.has_birth}}：</span>
              <span>{{origin_user_data.has_birth == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_birth == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 有无工伤保险 -->
            <div class="line" v-if="update_fields.includes('has_injury')">
              <span class="label_txt">{{dataText.has_injury}}：</span>
              <span>{{origin_user_data.has_injury == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_injury == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 有无住房公积金 -->
            <div class="line" v-if="update_fields.includes('has_fund')">
              <span class="label_txt">{{dataText.has_fund}}：</span>
              <span>{{origin_user_data.has_fund == 0 ? '无' : '有'}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.has_fund == 0 ? '无' : '有'}}</span>
            </div>
            <!-- 体检报告图片 -->
            <div class="line" v-if="update_fields.includes('medical_report_img')">
              <span class="label_txt">{{dataText.medical_report_img}}：</span>
              <el-image
                style="width: 40px; height: 40px"
                :src="origin_user_data.medical_report_img"
                fit="cover" :preview-src-list="[origin_user_data.medical_report_img]">
                  <div slot="error" class="image-slot" style="height:20px;line-height: 40px;">
                    无
                  </div>
                </el-image>
              <span style="margin: 0 10px;">改</span>
              <el-image
                style="width: 40px; height: 40px"
                :src="new_user_data.medical_report_img"
                fit="cover" :preview-src-list="[new_user_data.medical_report_img]">
                  <div slot="error" class="image-slot" style="height:20px;line-height: 40px;">
                    无
                  </div>
                </el-image>
            </div>
          </div>


          <!-- 毕业照 -->
          <div class="line" v-if="update_fields.includes('diploma')">
            <span class="label_txt">{{dataText.diploma}}：</span>
            <el-image
              style="width: 40px; height: 40px"
              :src="origin_user_data.diploma"
              fit="cover" :preview-src-list="[origin_user_data.diploma]">
                <div slot="error" class="image-slot" style="height:20px;line-height: 40px;">
                  无
                </div>
              </el-image>
            <span style="margin: 0 10px;">改</span>
            <el-image
              style="width: 40px; height: 40px"
              :src="new_user_data.diploma"
              fit="cover" :preview-src-list="[new_user_data.diploma]">
                <div slot="error" class="image-slot" style="height:20px;line-height: 40px;">
                  无
                </div>
              </el-image>
          </div>
          <!--****** 学历信息 ******-->
          <div v-if="update_fields.includes('educations')">
             <el-divider>学历信息</el-divider>
            <!-- 学历 -->
            <div class="line" v-if="new_user_data.educations[0].licence">
              <span class="label_txt">学历：</span>
              <span>{{origin_user_data.educations[0].licence}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.educations[0].licence}}</span>
            </div>
            <!-- 开始始时间 -->
            <div class="line" v-if="new_user_data.educations[0].start_date">
              <span class="label_txt">{{new_user_data.educations[0].key}}开始时间：</span>
              <span>{{origin_user_data.educations[0].start_date}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.educations[0].start_date}}</span>
            </div>
            <!-- 结束时间 -->
            <div class="line" v-if="new_user_data.educations[0].end_date">
              <span class="label_txt">{{new_user_data.educations[0].key}}结束时间：</span>
              <span>{{origin_user_data.educations[0].end_date}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.educations[0].end_date}}</span>
            </div>
            <!-- 学校/培训经历 -->
            <div class="line" v-if="new_user_data.educations[0].school">
              <span class="label_txt">{{origin_user_data.educations[0].key}}学校/培训经历：</span>
              <span>{{origin_user_data.educations[0].school}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.educations[0].school}}</span>
            </div>
            <!-- 专业/培训内容 -->
            <div class="line" v-if="new_user_data.educations[0].content">
              <span class="label_txt">{{origin_user_data.educations[0].key}}专业/培训内容：</span>
              <span>{{origin_user_data.educations[0].content}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.educations[0].content}}</span>
            </div>

            <!-- 学历2 -->
            <template v-if="new_user_data.educations.length > 1  && !!origin_user_data.educations[1].licence && !!new_user_data.educations[1].licence">
              <div class="line" v-if="new_user_data.educations[1].licence">
                <span class="label_txt">学历：</span>
                <span>{{origin_user_data.educations[1].licence}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.educations[1].licence}}</span>
              </div>
              <!-- 开始始时间2 -->
              <div class="line" v-if="new_user_data.educations[1].start_date">
                <span class="label_txt">{{new_user_data.educations[1].licence}}开始时间：</span>
                <span>{{origin_user_data.educations[1].start_date}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.educations[1].start_date}}</span>
              </div>
              <!-- 结束时间2 -->
              <div class="line" v-if="new_user_data.educations[1].end_date">
                <span class="label_txt">{{new_user_data.educations[1].licence}}结束时间：</span>
                <span>{{origin_user_data.educations[1].end_date}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.educations[1].end_date}}</span>
              </div>
              <!-- 学校/培训经历2 -->
              <div class="line" v-if="new_user_data.educations[1].school != ''">
                <span class="label_txt">{{origin_user_data.educations[1].licence}}学校/培训经历：</span>
                <span>{{origin_user_data.educations[1].school}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.educations[1].school}}</span>
              </div>
              <!-- 专业/培训内容2 -->
              <div class="line" v-if="new_user_data.educations[1].content != ''">
                <span class="label_txt">{{origin_user_data.educations[1].licence}}专业/培训内容：</span>
                <span>{{origin_user_data.educations[1].content}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.educations[1].content}}</span>
              </div>
            </template>

            <!-- 新增学历展示 -->
            <template v-if="new_user_data.educations.length > 1 && origin_user_data.educations[1].school == '' && !!new_user_data.educations[1].school">
              <div class="line">
                <span class="label_txt">新增学历：</span>
                <span>{{new_user_data.educations[1].licence}}</span>
              </div>
              <!-- 起始时间2 -->
              <div class="line" v-if="new_user_data.educations[1].licence != ''">
                <span class="label_txt">{{new_user_data.educations[1].licence}}起始时间：</span>
                <span>{{new_user_data.educations[1].start_date}}至{{new_user_data.educations[1].end_date}}</span>
              </div>
              <!-- 学校/培训经历2 -->
              <div class="line" v-if="new_user_data.educations[1].school != ''">
                <span class="label_txt">{{new_user_data.educations[1].licence}}学校/培训经历：</span>
                <span>{{new_user_data.educations[1].school}}</span>
              </div>
              <!-- 专业/培训内容2 -->
              <div class="line" v-if="new_user_data.educations[1].content != ''">
                <span class="label_txt">{{new_user_data.educations[1].licence}}专业/培训内容：</span>
                <span>{{new_user_data.educations[1].content}}</span>
              </div>
            </template>

            <!-- 删除学历展示 -->
            <template v-if="origin_user_data.educations.length > 1 && new_user_data.educations[1].key == ''">
              <div class="line">
                <span class="label_txt">刪除学历：</span>
                <span>{{origin_user_data.educations[1].key}}</span>
              </div>
            </template>
          </div>


          <!--******* 工作经历 ********-->
          <div v-if="update_fields.includes('jobs')">
            <el-divider>工作经历</el-divider>
            <!-- 工作单位1 -->
            <div class="line" v-if="new_user_data.jobs[0].company">
              <span class="label_txt">工作单位：</span>
              <span>{{origin_user_data.jobs[0].company}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].company}}</span>
            </div>
            <!-- 开始始时间1 -->
            <div class="line" v-if="new_user_data.jobs[0].start_date">
              <span class="label_txt">{{new_user_data.jobs[0].key}}开始时间：</span>
              <span>{{origin_user_data.jobs[0].start_date}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].start_date}}</span>
            </div>
            <!-- 结束时间1 -->
            <div class="line" v-if="new_user_data.jobs[0].end_date">
              <span class="label_txt">{{new_user_data.jobs[0].key}}结束时间：</span>
              <span>{{origin_user_data.jobs[0].end_date}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].end_date}}</span>
            </div>
            <!-- 工作岗位1 -->
            <div class="line" v-if="new_user_data.jobs[0].job">
              <span class="label_txt">{{origin_user_data.jobs[0].key}}工作岗位：</span>
              <span>{{origin_user_data.jobs[0].job}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].job}}</span>
            </div>
            <!-- 薪资水平1 -->
            <div class="line" v-if="new_user_data.jobs[0].salary">
              <span class="label_txt">{{origin_user_data.jobs[0].key}}薪资水平：</span>
              <span>{{origin_user_data.jobs[0].salary}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].salary}}</span>
            </div>
            <!-- 离职原因1 -->
            <div class="line" v-if="new_user_data.jobs[0].reason">
              <span class="label_txt">{{origin_user_data.jobs[0].key}}离职原因：</span>
              <span>{{origin_user_data.jobs[0].reason}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].reason}}</span>
            </div>
            <!-- 证明人电话1 -->
            <div class="line" v-if="new_user_data.jobs[0].contact">
              <span class="label_txt">{{origin_user_data.jobs[0].key}}证明人电话：</span>
              <span>{{origin_user_data.jobs[0].contact}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.jobs[0].contact}}</span>
            </div>

            <template  v-if="new_user_data.jobs.length > 1 && !!origin_user_data.jobs[1].company && !!new_user_data.jobs[1].company">
              <!-- 工作单位2 -->
              <div class="line" v-if="new_user_data.jobs[1].company">
                <span class="label_txt">工作单位：</span>
                <span>{{origin_user_data.jobs[1].company}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].company}}</span>
              </div>
             
               <!-- 开始始时间2 -->
              <div class="line" v-if="new_user_data.jobs[1].start_date">
                <span class="label_txt">{{new_user_data.jobs[1].key}}开始时间：</span>
                <span>{{origin_user_data.jobs[1].start_date}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].start_date}}</span>
              </div>
              <!-- 结束时间2 -->
              <div class="line" v-if="new_user_data.jobs[1].end_date">
                <span class="label_txt">{{new_user_data.jobs[1].key}}结束时间：</span>
                <span>{{origin_user_data.jobs[1].end_date}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].end_date}}</span>
              </div>
              <!-- 工作岗位2 -->
              <div class="line" v-if="new_user_data.jobs[1].job">
                <span class="label_txt">{{origin_user_data.jobs[1].job}}工作岗位：</span>
                <span>{{origin_user_data.jobs[1].job}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].job}}</span>
              </div>
              <!-- 薪资水平2 -->
              <div class="line" v-if="new_user_data.jobs[1].salary">
                <span class="label_txt">{{origin_user_data.jobs[1].salary}}薪资水平：</span>
                <span>{{origin_user_data.jobs[1].salary}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].salary}}</span>
              </div>
              <!-- 离职原因2 -->
              <div class="line" v-if="new_user_data.jobs[1].reason">
                <span class="label_txt">{{origin_user_data.jobs[1].reason}}离职原因：</span>
                <span>{{origin_user_data.jobs[1].reason}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].reason}}</span>
              </div>
              <!-- 证明人电话2 -->
              <div class="line" v-if="new_user_data.jobs[1].contact">
                <span class="label_txt">{{origin_user_data.jobs[1].contact}}证明人电话：</span>
                <span>{{origin_user_data.jobs[1].contact}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.jobs[1].contact}}</span>
              </div>
            </template>
            <!-- 新增工作信息展示 -->
            <template v-if="new_user_data.jobs.length > 1 && !origin_user_data.jobs[1].company && !!new_user_data.jobs[1].company">
              <div class="line">
                <span class="label_txt">新增工作单位：</span>
                <span>{{new_user_data.jobs[1].key}}</span>
              </div>
              <!-- 起始时间2 -->
              <div class="line">
                <span class="label_txt">{{origin_user_data.jobs[1].key}}起始时间：</span>
                <span>{{new_user_data.jobs[1].start_date}}至{{new_user_data.jobs[1].end_date}}</span>
              </div>
              <!-- 工作岗位2 -->
              <div class="line">
                <span class="label_txt">{{origin_user_data.jobs[1].key}}工作岗位：</span>
                <span>{{new_user_data.jobs[1].job}}</span>
              </div>
              <!-- 薪资水平2 -->
              <div class="line">
                <span class="label_txt">{{origin_user_data.jobs[1].key}}薪资水平：</span>
                <span>{{new_user_data.jobs[1].salary}}</span>
              </div>
              <!-- 离职原因2 -->
              <div class="line">
                <span class="label_txt">{{origin_user_data.jobs[1].key}}离职原因：</span>
                <span>{{new_user_data.jobs[1].reason}}</span>
              </div>
              <!-- 证明人电话2 -->
              <div class="line">
                <span class="label_txt">{{origin_user_data.jobs[1].key}}证明人电话：</span>
                <span>{{new_user_data.jobs[1].contact}}</span>
              </div>
            </template>

            <!-- 删除工作信息展示 -->
            <template v-if="origin_user_data.jobs.length > 1 && new_user_data.jobs[1].key == ''">
              <div class="line">
                <span class="label_txt">刪除工作单位：</span>
                <span>{{origin_user_data.jobs[1].key}}</span>
              </div>
            </template>
          </div>


          <!--******* 亲属信息  *********-->
          <div v-if="update_fields.includes('members')">
            <el-divider>亲属信息</el-divider>
            <!-- 姓名1 -->
            <div class="line" v-if="new_user_data.members[0].name">
              <span class="label_txt">姓名：</span>
              <span>{{origin_user_data.members[0].name}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.members[0].name}}</span>
            </div>
            <!-- 与本人关系1 -->
            <div class="line" v-if="new_user_data.members[0].relation">
              <span class="label_txt">{{new_user_data.members[0].key}}与本人关系：</span>
              <span>{{origin_user_data.members[0].relation}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.members[0].relation}}</span>
            </div>
            <!-- 年龄1 -->
            <div class="line" v-if="new_user_data.members[0].age">
              <span class="label_txt">{{new_user_data.members[0].key}}年龄：</span>
              <span>{{origin_user_data.members[0].age}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.members[0].age}}</span>
            </div>
            <!-- 地址工作单位1 -->
            <div class="line" v-if="new_user_data.members[0].address">
              <span class="label_txt">{{new_user_data.members[0].key}}地址工作单位：</span>
              <span>{{origin_user_data.members[0].address}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.members[0].address}}</span>
            </div>
            <!-- 联系电话1 -->
            <div class="line" v-if="new_user_data.members[0].phone">
              <span class="label_txt">{{new_user_data.members[0].key}}联系电话：</span>
              <span>{{origin_user_data.members[0].phone}}</span>
              <span style="margin: 0 10px;">改</span>
              <span>{{new_user_data.members[0].phone}}</span>
            </div>

            <template v-if="new_user_data.members.length > 1">
              <!-- 姓名2 -->
              <div class="line" v-if="new_user_data.members[1].name">
                <span class="label_txt">姓名：</span>
                <span>{{origin_user_data.members[1].name}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.members[1].name}}</span>
              </div>
              <!-- 与本人关系2 -->
              <div class="line" v-if="new_user_data.members[1].relation">
                <span class="label_txt">{{new_user_data.members[1].key}}与本人关系：</span>
                <span>{{origin_user_data.members[1].relation}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.members[1].relation}}</span>
              </div>
              <!-- 年龄2 -->
              <div class="line" v-if="new_user_data.members[1].age">
                <span class="label_txt">{{new_user_data.members[1].key}}年龄：</span>
                <span>{{origin_user_data.members[1].age}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.members[1].age}}</span>
              </div>
              <!-- 地址工作单位2 -->
              <div class="line" v-if="new_user_data.members[1].address">
                <span class="label_txt">{{new_user_data.members[1].key}}地址工作单位：</span>
                <span>{{origin_user_data.members[1].address}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.members[1].address}}</span>
              </div>
              <!-- 联系电话2 -->
              <div class="line" v-if="new_user_data.members[1].phone">
                <span class="label_txt">{{new_user_data.members[1].key}}联系电话：</span>
                <span>{{origin_user_data.members[1].phone}}</span>
                <span style="margin: 0 10px;">改</span>
                <span>{{new_user_data.members[1].phone}}</span>
              </div>
            </template>

          </div>

        </div>
      </div>
    </diV> 
    <!-- status 状态：1-待审核；2-审核通过；3-审核拒绝 -->
    <div slot="footer" class="dialog-footer" v-if="detailInfo.status == 1">
      <el-button @click="handleSubmit(2)">不同意</el-button>
      <el-button type="primary" @click="handleSubmit(1)">同意</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    name: 'staffApplyDetail',
    data() {
      return {
        visible: false,
        curRow: {},
        detailInfo: {},

        update_fields: [], // 修改的字段集合
        origin_user_data: {}, // 旧
        new_user_data: {}, // 新


        dataText:{
          // 基本信息
          residence: '现居地址',
          emergency_contact: '紧急联系人',
          emergency_contact_relation:'所属关系',
          phone: '联系电话',
          emergency_contact_relation: '所属关系',
          emergency_contact_phone: '联系电话',
          email: '电子邮箱',
          political_outlook_text: '政治面貌',
          marital_status_text: '婚姻状况',
          licence_img: '1寸证件照',
          diploma: '毕业照',

          // 其他信息
          bank_card_img: '银行卡正面',
          bank_card_no: '银行卡号',
          bank_name: '开户行',
          bank_card_name: '持卡人姓名',
          has_relative: '有无亲属在公司',
          // 有无亲属在公司
          relative_relation: '与本人关系',
          relative_name: '姓名',
          relative_title: '职务',
          // 保险
          has_endowment: '有无养老保险',
          has_medical: '有无医疗保险',
          has_unemployment: '有无失业保险',
          has_birth: '有无生育保险',
          has_injury: '有无工伤保险',
          has_fund: '有无住房公积金',
          medical_report_img: '体检报告图片',

        }
       
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        console.log(row);
        this.curRow = row;
        if(!!row) {
          this.$http.get('/admin/userSubmit/detail', {params:{id: row.id}}).then(res => {
            if(res.code === 1) {
              this.detailInfo = res.data;
                this.update_fields = res.data.update_fields; // 修改的字段集合
                this.origin_user_data = res.data.origin_user_data; // 旧
                this.new_user_data = res.data.new_user_data; // 新
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 审核
      handleSubmit(type) {
        // type 1：同意，2：不同意
        if(type == 1) {
          this.$confirm('请确认审核通过？','提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let _params = {
              id: this.curRow.id,
              status: 1,
            }
            this.verifyFun(_params)
          }).catch(() => {})
        } else {
          this.$prompt('请输入原因', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPattern: /^.+$/,  //  非空验证
            inputErrorMessage: '请输入原因'
          }).then(({ value }) => {
            let _params = {
              id: this.curRow.id,
              status: 2,
              check_reason: value, // 不同意原因
            }
            this.verifyFun(_params)
          }).catch(() => {});
        }
      },
      // 审核方法
      verifyFun(obj) {
        this.$http.post('/admin/userSubmit/check',obj).then(res => {
          if(res.code == 1) {
            this.$message.success("操作成功！")
            this.toggle(false)
            this.$emit('refresh')
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 字符转译
      toStr(data, key) {
        return data[key]
      }
    }
  }
</script>
<style scoped>
.line {
  margin-bottom: 10px;
}
.label_txt {
  display: inline-block;
  min-width: 120px !important;
  text-align: right;
  margin-right: 10px;
}
</style>
